@import url('react-loading-skeleton/dist/skeleton.css');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: var(--font-family) !important;
}

:root {
  --blue-100: #CBD5FA;
  --blue-200: #ABBAF6;
  --blue-300: #8995F0;
  --blue-400: #6D73E7;
  --blue-500: #534ED9;
  --blue-600: #4942C0;
  --blue-700: #3C389B;
  --blue-800: #35347B; 
  --blue-900: #201E48;

  --red-100: #FCA5A5;
  --red-500: #EF4444;
  --red-900: #B91C1C;

  --green-100: #86EFAD;
  --green-500: #22C55E;
  --green-900: #15803C;

  --orange-100: #FDAC74;
  --orange-500: #F97316;
  --orange-900: #C2570C;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

a {
  transition: filter .2s;
}

a:hover {
  filter: brightness(0.8);
}

button:disabled {
  cursor: not-allowed;
}

ul {
  list-style-type: none;
}

@media (max-width: 1080px) {
  html{
    font-size: 93.75%;
    scroll-behavior: smooth;
  }
}

@media (max-width: 720px) {
  html{
    font-size: 87.5%;
  }
}